import React, { useEffect, useState } from "react";
import Select from "react-select";

import "./analytics.css";
import "rsuite/dist/rsuite.min.css";

import { DatePicker } from "rsuite";
import moment from "moment";
import { getFilters, getStates } from "../../services/filter-api-services";

const styles = { width: "100%", display: "block", marginBottom: 10 };

const AnalyticsFilter = ({ getPostData }) => {
  const [stateOption, setStateOption] = useState([]);
  const [filters, setFilters] = useState([]);
  const [date, setDate] = useState(null);
  const [analyticsData, setAnalyticsData] = useState({});
  const [stateSelected, setStateSelected] = useState(null);
  const [selectedData, setSelectedData] = useState([]);

  useEffect(() => {
    setStateOption([{label
      : 
      "KARNATAKA",
      level
      : 
      "State",
      value
      : 
      "bac64d57-1dc8-47b4-ae96-ceec9b0c71c2"}])
    // fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    const { data, status } = await getStates();
    if (status === 200) {
      const { regions } = data?.data;

      let arr = regions.map((item) => {
        return {
          label: item.name,
          value: item.id,
          level: data?.data?.currentLevel,
        };
      });

      setStateOption([...arr]);
    }
  };

  const onChangeHandler = (element) => {
    const key = element?.level.toLowerCase();
    let filterData = [...filters];

    if (element.level !== "Village") {
      apiCall(element);
      setAnalyticsData((prev) => {
        return {
          ...prev,
          [key]: element.value,
        };
      });

      let index = filterData.findIndex(
        (item) => item.currentLevel === element?.level
      );
      let sliceData = filterData.slice(0, index + 1);

      setSelectedData(sliceData);
    } else if (element.level === "Village") {
      setAnalyticsData((prev) => {
        return {
          ...prev,
          [key]: element.value,
        };
      });
      let index = filterData.findIndex(
        (item) => item.currentLevel === element?.level
      );
      let sliceData = filterData.slice(0, index + 1);
      setSelectedData(sliceData);
    }
  };

  const apiCall = async (payload) => {
    const { data, status } = await getFilters(payload.value);
    var fil = [...filters];

    if (
      data?.data !== null &&
      fil.some((e) => e.currentLevel === data?.data.currentLevel)
    ) {
      let index = fil.findIndex(
        (x) => x.currentLevel === data.data.currentLevel
      );
      let new_slice_arr = fil.slice(0, index);
      setFilters([...new_slice_arr, data.data]);
    } else if (status === 200 && data?.data !== null) {
      fil.push(data.data);
      setFilters([...fil]);
    } else {
      let index = fil.findIndex((x) => x.currentLevel === payload?.level);
      let new_slice_arr = fil.slice(0, index + 1);
      setFilters([...new_slice_arr]);
    }
  };

  const getOptions = (arr, level) => {
    return arr.map((item) => {
      return {
        label: item.name,
        value: item.id,
        level: level,
      };
    });
  };

  const onChangeDate = (e) => {
    setDate(e);
  };

  const onSubmit = () => {
    let obj = {};
    selectedData.map((item) => {
      obj[item.currentLevel.toLowerCase()] =
        analyticsData[item.currentLevel.toLowerCase()];
      return null;
    });
    if (stateSelected?.value) {
      obj["state"] = stateSelected?.value;
    }
    if (date) {
      obj["date"] = moment(date).format("YYYY-MM-DD");
    }

    getPostData(obj);
  };

  return (
    <>
      <div className="select-group">
        <div className="filter-headings">
          <label>FILTERS</label>
          <span
            onClick={() => {
              setDate(null);
              setFilters([]);
              setStateSelected(null);
              setSelectedData([]);
              getPostData({});
            }}
          >
            Clear All
          </span>
        </div>

        <div className="filter-select">
          <div className="filter-group">
            <label>Date</label>
            <DatePicker
              placeholder="Select Date"
              style={styles}
              value={date}
              className="date-picker"
              format="yyyy-MM-dd"
              ranges={[
                {
                  label: "Now",
                  value: new Date(date),
                },
              ]}
              onChange={(e) => onChangeDate(e)}
            />
          </div>
          <div className="filter-group">
            <label>{"State"}</label>
            <Select
              placeholder="State*"
              classNamePrefix="react-select"
              options={stateOption}
              value={stateSelected}
              onChange={(e) => {
                onChangeHandler(e);
                setStateSelected(e);
              }}
            ></Select>
          </div>
          {filters &&
            filters.length !== 0 &&
            filters.map((filter) => {
              return (
                <div className="filter-group">
                  <label>{filter?.currentLevel}</label>
                  <Select
                    placeholder={filter?.currentLevel}
                    classNamePrefix="react-select"
                    options={getOptions(filter.regions, filter.currentLevel)}
                    onChange={(e) => onChangeHandler(e)}
                  ></Select>
                </div>
              );
            })}
        </div>
        <div>
          <button
            className="btn-search"
            onClick={() => {
              onSubmit();
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default AnalyticsFilter;
